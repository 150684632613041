
/*Lists*/
.lias-list .dx-item.dx-list-item {
    border-top: none;
    display: flex;
}

.lias-list.row-grey-background .dx-item.dx-list-item {
    background-color: #f4f4ff;
    margin-bottom: 5px;
    color: inherit;
}

.lias-list .dx-item-content.dx-list-item-content {
    order: 1;
}

.lias-list .dx-list-item-after-bag.dx-list-reorder-handle-container {
    order: 0;
    padding-top: 5px;
}

.lias-list.row-grey-background .dx-list .dx-empty-message,
.lias-list.row-grey-background .dx-list-item-content {
    padding: 0px;
}
.lias-list.horizontal-list div.dx-list-item {
    width: auto;
    display: inline-block;
    border-top: none;
    border: 2px solid #ececec;
    border-radius: 6px;
    margin: 6px;
}

.lias-buttonlist div.dx-list-item {
    width: fit-content;
    color:#414141;
    font-weight: 800;
    margin: 3px;
    border-radius: 6.25rem;
    border: 2px solid transparent;
}
.lias-buttonlist.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    border-color: #4B55F6;
    background-color: #4B55F6;
    border-color: #4B55F6;
    color:#ffffff;
}

.lias-buttonlist.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    border-color: #4B55F6;
    background-color: #4B55F6;
    border-color:#4B55F6;
    color:#ffffff;
}

.lias-buttonlist.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    border-color: #30379D;
    background-color: #30379D;
    border-color: #30379D;
    color:#ffffff;
}

.lias-buttonlist:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-hover:not(.dx-state-focused) {
    border-color: #30379D;
    background-color: #30379D;
    border-color: #30379D;
    color:#ffffff;
}

.lias-buttonlist .dx-list .dx-empty-message {
    padding: 5px 0 0 20px;
}