
/*Tree*/
.lias-treelist .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.lias-treelist .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td {
    background-color: inherit;
}

.lias-treelist .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.lias-treelist .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    background-color: inherit;
}

.lias-treelist .dx-treelist-container .dx-data-row:hover {
    background-color: #f4f4ff;
}

.lias-treelist.selectable .dx-treelist-container .dx-data-row {
    cursor: pointer;
}


.dx-treelist-rowsview .dx-treelist-expanded span::before {
    margin-top: -4px;
}

.dx-treelist-rowsview .dx-treelist-collapsed span::before {
    margin-top: -4px;
}

#zion-html .dx-treelist .dx-treelist-container .dx-treelist-header-panel {
    margin-bottom: 1rem;
}

.dx-header-row .dx-treelist-text-content {
    font-size: inherit;
    line-height: inherit;
    font-weight: 700;
    color: inherit;
    margin: 0;
    padding: 0;
}

.dx-treelist {

    ul {
        list-style-type: disc;
    }
    ol {
        counter-reset: level1 level2 level3;
        ol li {
            counter-increment: level1;
            &::marker {
                content: counter(level1, lower-alpha) ". ";
                }
    
            ol li {
                counter-increment: level2;
    
                &::marker {
                    content: counter(level2, lower-roman) ". ";
                }
    
                ol li {
                    counter-increment: level3;
    
                    &::marker {
                        content: counter(level3) ". ";
                    }
                }
            }
        }
    }
}
