.dx-theme-material-typography{
    font-size: 0.9rem!important;
    line-height: 1.2rem!important;
}
/*13px*/
.dx-widget, .dx-field, .dx-field-label, .dx-box-item-content,
.dx-overlay-wrapper, 
.dx-texteditor-input,
.dx-lookup-field,
.dx-overlay-wrapper,
.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-dropdowneditor-overlay .dx-list-group-header,
.dx-dropdowneditor-overlay .dx-list-item-content,
.dx-list-search.dx-searchbox .dx-placeholder::before,
.dx-list-group-header,
.dx-list-item-content,
.dx-list-select-all,
.dx-menu-base,
.dx-menu .dx-menu-item,
.dx-toolbar-menu-section .dx-list-item-content,
.dx-toolbar-button .dx-menu .dx-menu-item,
.dx-lookup.dx-invalid .dx-lookup-arrow::after,
.dx-accordion-item-title-caption,
.dx-datagrid .dx-placeholder::before,
.dx-datagrid-container .dx-placeholder::before,
.dx-datagrid .dx-row > td,
.dx-datagrid-headers .dx-lookup .dx-lookup-field,
.dx-datagrid-rowsview .dx-lookup .dx-lookup-field,
.dx-datagrid-column-chooser,
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item,
.dx-datagrid-drag-header,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-texteditor .dx-texteditor-input,
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content,
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-texteditor-input,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-group-panel,
.dx-datagrid-group-panel .dx-group-panel-item,
.dx-datagrid-rowsview .dx-row.dx-group-row,
.dx-treelist .dx-placeholder::before,
.dx-treelist-container .dx-placeholder::before,
.dx-treelist .dx-row > td,
.dx-treelist-headers .dx-lookup .dx-lookup-field,
.dx-treelist-rowsview .dx-lookup .dx-lookup-field,
.dx-editor-cell .dx-texteditor .dx-texteditor-input,
.dx-treelist-column-chooser,
.dx-treelist-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item,
.dx-treelist-drag-header,
.dx-treelist-headers .dx-treelist-table .dx-row > td,
.dx-treelist-filter-range-overlay .dx-overlay-content .dx-texteditor .dx-texteditor-input,
.dx-treelist-rowsview .dx-adaptive-detail-row .dx-field-item-content,
.dx-treelist-rowsview .dx-adaptive-detail-row .dx-texteditor-input,
.dx-treelist-filter-panel .dx-treelist-filter-panel-clear-filter,
.dx-treelist-filter-panel .dx-treelist-filter-panel-text,
.dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-area-data,
.dx-context-menu .dx-menu-item,
.dx-scheduler-agenda .dx-scheduler-appointment-title,
.dx-recurrence-numberbox-interval-label,
.dx-fa-button .dx-overlay-content .dx-fa-button-label-wrapper .dx-fa-button-label,
.dx-fa-button.dx-fa-button-main .dx-overlay-content .dx-fa-button-label,
.dx-calendar-cell
{
    font-family: inherit!important;
    font-size: 0.9rem!important;
    line-height: 1.2rem!important;
}
/*12px*/
.dx-list .dx-empty-message, .dx-progressbar-status,
.dx-datagrid .dx-column-indicators .dx-sort-index-icon,
.dx-datagrid-container .dx-column-indicators .dx-sort-index-icon,
.dx-treelist .dx-column-indicators .dx-sort-index-icon,
.dx-treelist-container .dx-column-indicators .dx-sort-index-icon,
.dx-pivotgrid-fields-container .dx-area-field.dx-area-box,
.dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-horizontal-headers,
.dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers,
.dx-pivotgridfieldchooser .dx-area .dx-area-caption,
.dx-fileuploader-file-status-message,
.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-tooltip-appointment-item .dx-tooltip-appointment-item-content .dx-tooltip-appointment-item-content-date,
.dx-calendar-body thead th
{
    font-family: inherit!important;
    font-size: 0.8rem!important;
    line-height: 1rem!important;
}

/*11px*/
.dx-badge,
.dx-scheduler-small .dx-scheduler-time-panel-cell > div,
.dx-scheduler-appointment-tooltip-wrapper .dx-overlay-content .dx-list .dx-list-item .dx-tooltip-appointment-item .dx-tooltip-appointment-item-content .dx-tooltip-appointment-item-content-date,
.dx-scheduler-overlay-panel .dx-overlay-content .dx-list .dx-list-item .dx-tooltip-appointment-item .dx-tooltip-appointment-item-content .dx-tooltip-appointment-item-content-date 
{
    font-family: inherit!important;
    font-size: 0.7rem!important;
    line-height: 0.9rem!important;
}