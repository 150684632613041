/*.dx-button-text {
    text-transform: none;
}

.dx-button-mode-text.dx-button-default {
    background-color: transparent;
    color: #4B55F6;
    border: 2px solid #F4F4F8;
    border-radius: 4px;    
}

.dx-button-mode-text.dx-button-default .dx-icon {
    color: #4B55F6;
}

.dx-button-mode-text.dx-button-default.dx-state-selected {
    background-color: #F4F4F8;
}

.dx-button-mode-text.dx-button-default.dx-state-selected,
.dx-button-mode-text.dx-button-default.dx-state-selected .dx-icon {
    color: #4B55F6;
}

.dx-button-mode-text.dx-button-default.dx-state-focused,
.dx-button-mode-text.dx-button-default.dx-state-hover {
    background-color: #F4F4F8;
}

.dx-button-mode-text.dx-button-default.dx-state-active {
    background-color: #F4F4F8;
}

.dx-button-mode-text.dx-button-default .dx-inkripple-wave {
    background-color: #F4F4F8;
}
*/

.lias-button {
    text-transform: uppercase;
    font: 800 1rem / 1.125rem Nunito;
    letter-spacing: 0.1em;
    border: 2px solid transparent;
    border-radius: 6.25rem;  
    line-height: 1;
}

.lias-button .dx-icon {
    font-weight: 300;
    font-size: 1.2rem;
}

.lias-button .dx-button-text {
    font-weight: 800;
    font-size: 0.8rem;
}

.dx-toolbar-button .lias-button .dx-icon {
    font-weight: 300;
    font-size: 1.6rem;
}

.lias-button.dx-button-default {    
    background-color: #4B55F6;  
    border-color:#4b55f600;    
}
.lias-button.dx-button-success {
    background-color: #8bc34a;
    border-color:#8bc34a;
}
.lias-button.dx-button-danger {
    background-color: #f44336; 
    border-color:#f44336;
} 
.lias-button.dx-button-normal-inverted {    
    background-color: #ffffff;  
    border-color:rgba(145, 145, 145, 0);
}

/*Text buttons*/
.lias-button.dx-button-mode-text {
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 6.25rem;  
}

.lias-button.dx-button-mode-text.dx-state-hover:not(.dx-button-normal-inverted),
.lias-button.dx-button-mode-text.dx-state-focused:not(.dx-button-normal-inverted) {
    background-color: rgba(75, 85, 246, 0.05);
    border-color:rgba(75, 85, 246, 0);
}
.lias-button.dx-button-mode-text.dx-state-hover.dx-button-normal-inverted,
.lias-button.dx-button-mode-text.dx-state-focused.dx-button-normal-inverted {
    background-color: rgba(255, 255, 255, 0.05);
    border-color:rgba(255, 255, 255, 0.05);
}

/* --- */
.lias-button.dx-button-mode-text.dx-button-default.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}
.lias-button.dx-button-mode-text.dx-button-default.dx-state-hover .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-hover .dx-icon,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-focused .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-focused .dx-icon {
    color: #1f1f1f;
}

.lias-button.dx-button-mode-text.dx-button-default .dx-icon,
.lias-button.dx-button-mode-text.dx-button-default .dx-button-text {
    color: #4B55F6;
}

.lias-button.dx-button-mode-text.dx-button-default.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}

.lias-button.dx-button-mode-text.dx-button-default.dx-state-hover .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-hover .dx-icon,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-focused .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-default.dx-state-focused .dx-icon {
    color: #30379D;
}

.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-hover .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-hover .dx-icon,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-focused .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-focused .dx-icon {
    background-color: rgba(75, 85, 246, 0.05);
    border-color:rgba(75, 85, 246, 0);
}

.lias-button.dx-button-mode-text.dx-button-normal-inverted .dx-icon,
.lias-button.dx-button-mode-text.dx-button-normal-inverted .dx-button-text {
    color: #FFFFFF;
}

.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}

.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-hover .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-hover .dx-icon,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-focused .dx-button-text,
.lias-button.dx-button-mode-text.dx-button-normal-inverted.dx-state-focused .dx-icon {
    color: #30379D;
}

.lias-button.dx-button-mode-outlined,
.lias-button.dx-button-mode-contained {    
    border-width: 2px;
    border-style: solid;
    border-radius: 6.25rem;
}

/*Outlined buttons*/
.lias-button.dx-button-mode-outlined {
    background-color: transparent;
}
.lias-button.dx-button-mode-outlined.dx-state-disabled 
{
    border-color: #c0c0c0; 
}

.lias-button.dx-button-mode-outlined.dx-button-default {
    border-color: #4b55f6;
}

.lias-button.dx-button-mode-outlined.dx-button-normal {
    border-color: #4b55f6;
}

.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-hover,
.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-focused {
    background-color: rgba(75, 85, 246, 0.05);
    border-color: #30379D;    
}

/* --- */
.lias-button.dx-button-mode-outlined.dx-button-default .dx-icon,
.lias-button.dx-button-mode-outlined.dx-button-default .dx-button-text {
    color: #4B55F6;
}

.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-hover .dx-button-text,
.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-hover .dx-icon,
.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-focused .dx-button-text,
.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-focused .dx-icon {
    color: #30379D;
}

.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-outlined.dx-button-default.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}

/*Contained buttons*/
.lias-button.dx-button-mode-contained.dx-button-default {
    background-color: #4b55f6;
    border-color: #4b55f6;
    color: #ffffff;
}
.lias-button.dx-button-mode-contained.dx-button-default.dx-state-disabled,
.lias-button.dx-button-mode-contained.dx-button-success.dx-state-disabled, 
.lias-button.dx-button-mode-contained.dx-button-danger.dx-state-disabled   
{
    background-color: #eaeaea; 
    border-color:#c0c0c0;
    color: #5c5c5c;
}

.lias-button.dx-button-mode-contained.dx-button-default.dx-state-hover,
.lias-button.dx-button-mode-contained.dx-button-default.dx-state-focused {
    background-color: #30379D;    
    border-color: #30379D;    
}

.lias-button.dx-button-mode-contained.dx-state-disabled .dx-button-text,
.lias-button.dx-button-mode-contained.dx-state-disabled .dx-icon {
    color: #a1a1a1;
}