
/* Customized CSS */
.dx-htmleditor {
    border: 0;
}

/*Can't be done special for html editor yet*/
.dx-formdialog-field-target .dx-checkbox-container {
    display: none;
}

.dx-htmleditor.dx-htmleditor-outlined {
    box-shadow: unset;
}

.dx-htmleditor.dx-htmleditor-outlined.dx-state-hover {
    box-shadow: unset;
}

.dx-htmleditor.dx-htmleditor-outlined.dx-state-focused {
    box-shadow: unset;
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget 
{
    opacity: .70;
} 
