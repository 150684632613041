/* Customized CSS DataTable*/
.dx-datagrid {
    margin-bottom: 1rem;
}

/* The dropdown for pager to be wider */
.dx-page-sizes .dx-texteditor.dx-editor-outlined {
    box-shadow: unset;
    border-width: 1px;
    width: 80px!important;
}

.liasDatagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>td {
    vertical-align: middle;

    ul {
        list-style-type: disc;
    }
    ol {
        counter-reset: level1 level2 level3;
        ol li {
            counter-increment: level1;
            &::marker {
                content: counter(level1, lower-alpha) ". ";
                }
    
            ol li {
                counter-increment: level2;
    
                &::marker {
                    content: counter(level2, lower-roman) ". ";
                }
    
                ol li {
                    counter-increment: level3;
    
                    &::marker {
                        content: counter(level3) ". ";
                    }
                }
            }
        }
    }
}

.liasDatagrid.full-width-searchpanel .dx-toolbar .dx-texteditor {
    width: 100% !important;
}

.liasDatagrid.full-width-searchpanel .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
    width: 100% !important;
}

.liasDatagrid.full-width-searchpanel .dx-datagrid-search-panel {
    margin-left: 0px;
}

.liasDatagrid.row-cursor-pointer .dx-datagrid-content .dx-datagrid-table .dx-row:not(.dx-row-focused):hover>td,
.liasDatagrid.row-cursor-pointer .dx-datagrid-content .dx-datagrid-table .dx-row:not(.dx-row-focused):hover>tr>td {
    cursor: pointer;
}

.liasDatagrid .dx-datagrid-rowsview .dx-row.dx-group-row td strong {
    color: #000000;
}

.liasDatagrid .dx-datagrid-header-panel {
    border: none;
    padding: 0px;
    margin-bottom: 0.5rem
}

.liasDatagrid .focused-row {
    background-color: rgba(75, 85, 246, 0.24);
    cursor: pointer;
}

.liasDatagrid .bolded-row {
    font-weight: 600;
    cursor: pointer;
}

.liasDatagrid .dx-freespace-row {
    height: 0px !important;
}

.liasDatagrid .dx-link-icon {
    color: #4B55F6;
}

.separatorStyle {
    width: 1px;
    height: 24px;
    background-color: '#ccc';
    margin: 0 8px;
};

/* Customized CSS ColumnChooser title in popup : Donb't show*/
.dx-datagrid-column-chooser .dx-popup-title {
    min-height: unset;
}

.liasDatagrid .dx-toolbar-items-container {
    height: 5rem !important;
    overflow: visible;
}

.dx-datagrid-column-chooser .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: unset;
    overflow: visible;
}

/* Specific for previewed datatable, rendered in publisher */
#zion-html .data-table-control .dx-datagrid-content .dx-datagrid-table .dx-row>td>p,
#zion-html .data-table-control .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td>p {
    margin-bottom: 0;
}

#zion-html .data-table-control .dx-datagrid .dx-datagrid-header-panel {
    margin-bottom: 1rem;
}

#zion-html .data-table-control .dx-datagrid-rowsview .dx-group-row.dx-row > td {
    border-left-color: #e0e0e0;
    border-right-color: #e0e0e0;
}

#zion-html .data-table-control .dx-datagrid-total-footer:focus-within {
    outline: #4B55F6 solid 2px;
    outline-offset: -2px;
}